import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  client: { general: {}, contentEngine: {} }
};

const store = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
};

export default store;

import stbAPIGateway from '../../../../services/stbAPIGateway';

// eslint-disable-next-line import/prefer-default-export
export const patchClientContentEngine = async ({ rootGetters, dispatch }, contentEngine) => {
  const axios = rootGetters['app/stbAxios'];

  const stbApi = await stbAPIGateway(axios, process.env.API_URL);

  const response = await stbApi.patchClientContentEngineBySid(
    contentEngine.clientSid,
    contentEngine
  );
  if (response.status === 'success') {
    await dispatch('fetchClientContentEngine', contentEngine.clientSid);
  }
  return response;
};

export default (axios, baseURL) => async (clientSid, contentEngine) => {
  const url = `/v1/clients/${encodeURIComponent(clientSid)}/content-engine`;

  const axiosConfig = {
    method: 'PATCH',
    baseURL,
    url,
    data: { ...contentEngine }
  };
  try {
    const axiosResponse = await axios(axiosConfig);

    if (!axiosResponse || !axiosResponse.data || typeof axiosResponse.data !== 'object') {
      throw new Error('Invalid response from gateway');
    }
    return axiosResponse.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return { error: error.response.data };
    }
    throw error;
  }
};

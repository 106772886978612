import getHealthAlive from './getHealthAlive';
import getClientGeneral from './clients/getClientGeneral';
import patchClientGeneralBySid from './clients/patchClientGeneralBySid';
import getClientContentEngine from './clients/getClientContentEngine';
import deleteRiskCaches from './risks/deleteRiskCaches';
import patchClientContentEngineBySid from './clients/patchClientContentEngineBySid';

export default (axios, baseUrl) => {
  return {
    getHealthAlive: () => getHealthAlive(axios, baseUrl),
    getClientGeneral: getClientGeneral(axios, baseUrl),
    patchClientGeneralBySid: patchClientGeneralBySid(axios, baseUrl),
    getClientContentEngine: getClientContentEngine(axios, baseUrl),
    deleteRiskCaches: deleteRiskCaches(axios, baseUrl),
    patchClientContentEngineBySid: patchClientContentEngineBySid(axios, baseUrl)
  };
};

import { fetchClientGeneral } from './fetchClientGeneral';
import { patchClientGeneral } from './patchClientGeneral';
import { fetchClientContentEngine } from './fetchClientContentEngine';
import { patchClientContentEngine } from './patchClientContentEngine';

export default {
  patchClientGeneral,
  fetchClientGeneral,
  fetchClientContentEngine,
  patchClientContentEngine
};

<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="additionalInfoList">
    <v-flex xs12 class="additional-info-list-info-text">
      <i class="fas fa-info-circle"></i>
      <!-- eslint-disable -->
        This section allows you to upload files and add typed notes. For example, additional information could include risk assessments provided by external providers.
        <!-- eslint-enable -->
      <br /><br />
      You can upload one or more file(s) including additional relevant documents such as risk
      assessments or relevant medical information. Alternatively, you can enter in notes in the Note
      section. Add additional files or notes as required by clicking Add New.
    </v-flex>
    <div :key="index" v-for="(additional, index) in additionalInformationItems">
      <div v-if="!additional.isDeleted">
        <div v-if="!additional.notes">
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12>
                  <input
                    :id="getUploadFileId(index)"
                    style="display: none"
                    type="file"
                    :accept="fileTypeAccept"
                    @change="handleTemplateFileChange($event, additional, index)"
                  />
                  <cs-button
                    :id="getUploadButtonId(index)"
                    @click="uploadFileClick(index)"
                    :disabled="!formControlEnabled"
                    primary
                    leftIcon="fa fa-upload"
                    label="Upload"
                    class="excursion-stepper-upload-btn"
                  ></cs-button>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="form-label safe-trip-font"> File Name </v-flex>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12>
                  {{ additional.filename }}
                  <v-icon
                    :id="getDownloadFileId(index)"
                    v-if="additional.sid"
                    @click="downloadFile(additional)"
                    medium
                    style="font-size: 15px; height: 17px; margin-left: 0.7%"
                    >fa fa-download</v-icon
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="form-label safe-trip-font"> Uploaded Date </v-flex>
            <v-flex xs12>
              {{ getUploadDate(additional.fileUpdateDate) }}
            </v-flex>
          </v-layout>
        </div>
        <v-layout wrap>
          <v-flex xs12 class="form-label safe-trip-font">
            Summary
            <span v-show="showFirstMandatory(additional)" class="red--text">*</span>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              :id="getFileSummaryId(index)"
              solo
              placeholder="Summary"
              class="name-textbox safe-trip-font excursion-input"
              v-model="additional.fileSummary"
              @input="additionalValueChange(additional, index)"
              :disabled="!formControlEnabled"
            />
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="!additional.notes">
          <v-flex xs12 class="form-label safe-trip-font excursion-stepper-upload-comment">
            File Description</v-flex
          >
          <v-flex xs12>
            <div>
              <v-textarea
                :id="getDescriptionId(index)"
                solo
                placeholder="Add a file description or add notes without uploading a file"
                rows="4"
                class="textarea-comment excursion-input"
                height="100px"
                style="margin-top: 0px"
                :no-resize="true"
                v-model="additional.comment"
                @change="additionalValueChange(additional, index)"
                :disabled="!formControlEnabled"
              ></v-textarea>
            </div>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="!additional.filename">
          <v-flex xs12 class="form-label safe-trip-font excursion-stepper-upload-comment">
            Notes
            <span v-show="showFirstMandatory(additional)" class="red--text">*</span>
          </v-flex>
          <v-flex xs12>
            <div>
              <v-textarea
                solo
                :id="getNotesId(index)"
                placeholder="Notes"
                rows="4"
                class="textarea-comment excursion-input"
                height="100px"
                style="margin-top: 0px"
                :no-resize="true"
                v-model="additional.notes"
                @input="additionalValueChange(additional, index)"
                :disabled="!formControlEnabled"
              ></v-textarea>
            </div>
          </v-flex>
        </v-layout>
        <v-layout>
          <cs-button
            :id="getAddFileId(index)"
            v-if="showAddFileButton(index)"
            @click="addAdditionalInfo()"
            small
            class="add-additional-btn"
            label="Add New"
            :disabled="!formControlEnabled"
          ></cs-button>
          <cs-button
            :id="getDeleteId(index)"
            v-if="additional.showDeleteButton"
            @click="deleteAdditionalInfoConfirm(index)"
            small
            delete
            class="delete-additional-btn"
            :class="{ 'continue-btn': !showAddFileButton(index) }"
            label="Remove"
            :disabled="!formControlEnabled"
          ></cs-button>
        </v-layout>
      </div>
    </div>
    <cs-form-dialog
      v-model="deletionConfirmationDialogDisplayed"
      id="dlg-confirm-additional-info-list"
      bodyId="dlg-body-confirm-additional-info-list"
      heading="Remove Additional Information"
      :primaryAction="{
        label: 'Remove',
        eventName: 'primary-click'
      }"
      @primary-click="deleteAdditionalInfo"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="deletionConfirmationDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text
            >You are about to remove additional information, would you like to continue?
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="invalidFileTypeDialogDisplayed"
      id="dlg-invalid-file-type"
      bodyId="dlg-body-invalid-file-type"
      heading="Upload Additional Information"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click'
      }"
      @primary-click="invalidFileTypeDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text>
            Invalid file type:
            <span class="debug-error-message">{{ invalidFileTypeName }}</span> <br />
            Sorry, <span class="debug-error-message">{{ uploadFileType }}</span> file type is not
            supported.
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
export default {
  props: ['additionalInformationItems', 'formControlEnabled'],
  data: () => ({
    deletionConfirmationDialogDisplayed: false,
    invalidFileTypeDialogDisplayed: false,
    invalidFileTypeName: '',
    selectedAdditional: {},
    fileTypeAccept: `.pdf,.csv,.doc,.docx,.xls,.xlsx,.txt,.odt,.ods,.odp,.png,.jpeg,.jpg`,
    fileMimeTypeAccept: [
      'application/pdf',
      'text/csv',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.presentation',
      'image/png',
      'image/jpeg',
      'image/jpg'
    ],
    uploadFileType: ''
  }),
  methods: {
    addAdditionalInfo() {
      const additionalInformationItemsAdded = this.additionalInformationItems.filter(
        (r) => !r.isDeleted
      );
      let showDeleteButton = true;
      if (additionalInformationItemsAdded.length === 0) {
        showDeleteButton = false;
      }

      this.additionalInformationItems.push({
        fileSummary: '',
        comment: '',
        showDeleteButton
      });

      this.$forceUpdate();
      this.setAdditionalInformationValid();
    },
    deleteAdditionalInfoConfirm(index) {
      this.selectedAdditional.index = index;
      this.deletionConfirmationDialogDisplayed = true;
    },
    deleteAdditionalInfo() {
      const { index } = this.selectedAdditional;
      const items = this.additionalInformationItems;
      const item = this.additionalInformationItems[index];

      if (item.sid) {
        item.isDeleted = true;
      } else {
        items.splice(index, 1);
      }
      this.deleteAttachment(item);
      this.deletionConfirmationDialogDisplayed = false;
      return true;
    },
    async deleteAttachment(item) {
      const items = this.additionalInformationItems;
      const additionalInformationItemsAdded = items.filter((a) => {
        return !a.isDeleted;
      });

      if (additionalInformationItemsAdded.length === 0) {
        this.addAdditionalInfo();
      }

      const excursionId = this.$store.state.common.excursionDc.id;
      if (item.isDeleted) {
        const argHash = {
          queryParam: this.$store.state.common.apiParam,
          excursionId,
          attachmentSid: item.sid,
          store: this.$store
        };
        await this.$app.stbApiAdapter.deleteExcursionAttachments(argHash);
      }
      this.setAdditionalInformationValid();
    },
    getUploadFileId(index) {
      return `uploadFile_${index}`;
    },
    getUploadButtonId(index) {
      return `additional-info-list--upload-${index}`;
    },
    getDownloadFileId(index) {
      return `additional-info-list--download---button-${index}`;
    },
    getFileSummaryId(index) {
      return `additional-info-list--file-summary-${index}`;
    },
    getDescriptionId(index) {
      return `additional-info-list--description-${index}`;
    },
    getNotesId(index) {
      return `additional-info-list--notes--text-input-${index}`;
    },
    getAddFileId(index) {
      return `additional-info-list--add-file-${index}`;
    },
    getDeleteId(index) {
      return `additional-info-list--delete-${index}`;
    },
    uploadFileClick(index) {
      document.getElementById(`uploadFile_${index}`).click();
    },
    getUploadDate(uploadDate) {
      let timezoneDate = '';
      if (uploadDate) {
        const localDate = this.$app.moment(uploadDate);
        timezoneDate = this.$app
          .moment(localDate)
          .utc()
          .tz(this.$store.state.common.clientTimezone)
          .format('MM/DD/YYYY, hh:mm:ss A');
      }
      return timezoneDate;
    },
    handleTemplateFileChange(e, additional, index) {
      if (e && e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const filename = file.name;
        const extensionType = filename.split('.').pop();
        const fileType = file.type;

        if (
          this.fileTypeAccept.indexOf(extensionType) < 0 ||
          !this.fileMimeTypeAccept.includes(fileType)
        ) {
          this.invalidFileTypeDialogDisplayed = true;
          this.uploadFileType = `.${extensionType}`;
          this.invalidFileTypeName = filename;
          e.target.value = '';
          return;
        }

        this.$set(additional, 'file', file);

        this.$set(additional, 'filename', file.name);
        // TODO: Use the date from API
        this.$set(additional, 'fileUpdateDate', '');
        this.$forceUpdate();

        this.additionalValueChange(additional, index);

        this.setAdditionalInformationValid();
      }
    },
    showAddFileButton(index) {
      const additionalInformationItemsAdded = this.additionalInformationItems.filter((a) => {
        return !a.isDeleted;
      });

      if (additionalInformationItemsAdded.length === 1) {
        return true;
      }

      // Allow uploading up to 50 files
      if (additionalInformationItemsAdded.length === 50) {
        return false;
      }

      for (let i = this.additionalInformationItems.length - 1; i > 0; i -= 1) {
        if (!this.additionalInformationItems[i].isDeleted) {
          if (index === i) {
            return true;
          }
          break;
        }
      }

      return false;
    },
    additionalValueChange(additional, index) {
      const localAdditional = additional;
      if (index === 0) {
        if (
          localAdditional.filename ||
          localAdditional.fileSummary ||
          localAdditional.comment ||
          localAdditional.notes
        ) {
          localAdditional.showDeleteButton = true;
        } else {
          localAdditional.showDeleteButton = false;
        }
      }
      if (localAdditional.notes && localAdditional.notes.length > 0) {
        localAdditional.comment = '';
      }
      this.$forceUpdate();
      localAdditional.isUpdated = true;
      this.setAdditionalInformationValid();
    },
    setAdditionalInformationValid() {
      const additionalInformationItemsAdded = this.additionalInformationItems.filter((a) => {
        return !a.isDeleted;
      });

      let additionalInformationValid = false;
      if (additionalInformationItemsAdded) {
        if (additionalInformationItemsAdded.length === 1) {
          const additional = additionalInformationItemsAdded[0];
          if (
            (additional.filename && additional.fileSummary) ||
            (!additional.filename && additional.fileSummary !== '' && additional.notes) ||
            (!additional.filename &&
              !additional.fileSummary &&
              !additional.comment &&
              !additional.notes)
          ) {
            additionalInformationValid = true;
          }
        } else {
          additionalInformationValid = additionalInformationItemsAdded.every((additional) => {
            return (
              (additional.filename && additional.fileSummary) ||
              (!additional.filename && additional.fileSummary !== '' && additional.notes)
            );
          });
        }
      }

      this.$emit('setAdditionalInformationValid', additionalInformationValid);
    },
    async downloadFile(additional) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: additional.sid,
        store: this.$store
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (attachments.length > 0) {
          const file = attachments[0];

          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, '');
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, '');
            const fileUrl = `${apiUrl}/${filePath}`;
            const { filename } = file.metadata;
            const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
              store: this.$store,
              app: this.$app
            });
            excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },
    showFirstMandatory(additional) {
      if (additional.isDeleted) {
        return false;
      }

      const additionalInformationItemsAdded = this.additionalInformationItems.filter((a) => {
        return !a.isDeleted;
      });
      if (additionalInformationItemsAdded && additionalInformationItemsAdded.length === 1) {
        const additionalInfo = additionalInformationItemsAdded[0];
        if (
          additionalInfo.filename ||
          additionalInfo.fileSummary ||
          additionalInfo.comment ||
          additionalInfo.notes
        ) {
          return true;
        }

        return false;
      }

      return true;
    }
  }
};
</script>

<template>
  <div>
    <v-row xs12 no-gutters>
      <v-col>
        <cs-button
          id="btnUploadNewImage"
          primary
          label="Upload New Image"
          @click="selectFile()"
        ></cs-button>

        <label for="fileSelect">
          <input
            type="file"
            ref="fileSelect"
            id="fileSelect"
            v-show="false"
            @change="setImage"
            accept="image/png, image/jpeg, image/jpg"
          />
        </label>
      </v-col>
    </v-row>
    <v-row xs12 no-gutters>
      <v-col class="mt-2 ml-2">
        <vue-cropper
          ref="cropper"
          :auto-crop-area="1"
          :src="imageSrc"
          alt="Client Logo"
          :imgStyle="{ height: '200px' }"
          v-show="imageSrc != ''"
          :cropmove="cropMove"
        ></vue-cropper>
      </v-col>
    </v-row>
    <v-row xs12 no-gutters>
      <v-col>
        <v-card class="crop-img" v-show="cropImg != ''">
          <v-img
            id="image-upload-crop-image"
            max-width="346px"
            max-height="100px"
            width="346px"
            height="100px"
            :src="cropImg"
            :contain="true"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row xs12 no-gutters>
      <v-col>
        <cs-button
          v-show="imageSrc != ''"
          id="btnCropImage"
          primary
          label="Crop Image"
          @click="cropImage"
          :disabled="disableCropButton"
        ></cs-button>
      </v-col>
    </v-row>
    <cs-form-dialog
      v-model="noticeDialogDisplayed"
      id="dlg-image-upload-notice"
      bodyId="dlg-image-upload-notice-body"
      heading="Image Upload"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click'
      }"
      @primary-click="noticeDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <div v-safe-html="noticeDialogMessage"></div>
      </template>
    </cs-form-dialog>
  </div>
</template>
<script>
import VueCropper from 'vue-cropperjs';

export default {
  name: 'ImageUpload',

  components: {
    VueCropper
  },

  props: {
    imageUrl: { type: String, required: false, default: '' },
    disableCropButton: { type: Boolean, required: false, default: false },
    resetView: { type: Boolean, required: false, default: false }
  },

  watch: {
    imageUrl() {
      this.cropImg = this.imageUrl;
    },
    resetView() {
      if (this.resetView) {
        this.reset();
      }
    }
  },

  data() {
    return {
      imageSrc: '',
      logoFile: '',
      cropImg: this.imageUrl || '',
      fileTypeAccept: `.png, .jpeg, .jpg`,
      fileMimeTypeAccept: [`image/png`, `image/jpeg`, `image/jpg`],
      noticeDialogDisplayed: false,
      noticeDialogMessage: ''
    };
  },
  methods: {
    selectFile() {
      this.$refs.fileSelect.click();
    },

    setImage(e) {
      const file = e.target.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      const fileType = file.type;
      this.logoFile = file;

      if (
        this.fileMimeTypeAccept.indexOf(fileType) < 0 ||
        !this.fileTypeAccept.includes(fileExtension)
      ) {
        this.alertCheckLogoFormat('Please select an image file.');
        e.target.value = '';
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imageSrc = event.target.result;

          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          }
        };

        reader.readAsDataURL(file);
        this.$emit('imageChanged', file);
      } else {
        this.alertCheckLogoFormat('Sorry, FileReader API not supported.');
      }
    },

    cropImage() {
      // TODO: Fix image cropping
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL(this.logoFile.type);
      if (`${this.cropImg}` === '') return;

      this.getCroppedBlob();
    },

    getCroppedBlob() {
      const fileName = this.logoFile.name;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.logoFile = new File([blob], fileName, { type: blob.type });
        this.$emit('imageChanged', this.logoFile);
      });
    },

    cropMove() {},

    alertCheckLogoFormat(errorMsg) {
      this.noticeDialogDisplayed = true;
      this.noticeDialogMessage = errorMsg;
    },

    reset() {
      this.imageSrc = '';
      this.logoFile = '';
      this.cropImg = '';
      this.cropImg = this.imageUrl;
      this.noticeDialogMessage = '';
      this.noticeDialogDisplayed = false;
    }
  }
};
</script>

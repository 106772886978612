<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <div class="client-admin-groups-btn-container">
      <cs-button
        primary
        id="clientAdminGroupsDownload"
        @click="handleDownloadGroupButtonClick"
        label="Download Groups"
      ></cs-button>

      <cs-button
        primary
        id="clientAdminGroupsUpload"
        @click="groupImportModalDisplayed = true"
        label="Upload Groups"
      ></cs-button>
    </div>

    <div class="client-admin-groups-message-container">
      <span v-if="!hasSchoolGroups">You have not populated your school groups for PlanCheckGo</span>
      <span v-if="hasSchoolGroups">Your school groups have been set up for PlanCheckGo</span>
    </div>
    <cs-form-dialog
      v-model="groupImportModalDisplayed"
      id="dlg-import-groups"
      heading="Upload Groups"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        loading: groupImportModalSaveActionInProgress,
        disabled: groupImportModalSaveActionInProgress
      }"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      bodyId="group-import-modal--generic-dialog"
      @primary-click="saveClientUserImportModal"
      @secondary1-click="resetThenCloseGroupImportModal"
    >
      <template v-slot:cs-form-dialog-content>
        <v-form ref="group-import-modal--form" lazy-validation class="group-import-modal-form">
          <v-spacer></v-spacer>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12>
                <input
                  :id="groupsImportFileInputId"
                  style="display: none"
                  type="file"
                  :accept="groupImportFileExtensionAttr"
                  @change="handleGroupImportFileInputChange($event)"
                  aria-label="groupsImportFileInputId"
                />
                <cs-button
                  id="group-import-file-selection-button"
                  primary
                  class="excursion-stepper-upload-btn"
                  @click="handleGroupImportFileSelectionClick"
                  leftIcon="fa fa-upload"
                  label="Upload Group List CSV"
                ></cs-button>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="groupImportFileInfo" xs12 class="form-label template-file-font">
            File Name
          </v-flex>
          <v-flex v-if="groupImportFileInfo" xs12>
            <v-layout wrap>
              <v-flex xs12 style="margin-left: 3px">
                {{ groupImportFileInfoName }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="groupImportFileInputErrorMessage" class="client-user-import-modal-error">
            {{ groupImportFileInputErrorMessage }}
          </v-flex>
        </v-form>
      </template>
    </cs-form-dialog>

    <cs-form-dialog
      v-model="groupImportGenericDialogDisplayed"
      id="dlg-generic"
      :heading="groupImportGenericDialogHeader"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click',
        disabled: groupImportModalSaveActionInProgress
      }"
      bodyId="group-import-modal--generic-dialog"
      @primary-click="handlegroupImportGenericDialogOkButtonClick(groupImportGenericDialogMessage)"
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text>
          <div v-safe-html="groupImportGenericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
  </v-container>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  extends: CSBase,

  data() {
    return {
      groupImportFileExtensionAttr: `.csv`,
      groupImportMimeTypeAllowed: ['text/csv'],
      groupsImportFileInputId: 'group-import-file-input',
      groupImportModalDisplayed: false,
      groupImportFileInfo: '',
      groupImportFileInputErrorMessage: '',
      groupImportModalSaveActionInProgress: false,
      groupImportGenericDialogDisplayed: false,
      groupImportGenericDialogHeader: 'Groups Status',
      groupImportGenericDialogMessage: '',
      groupImportGenericDialogSuccessMessage: 'Groups have been imported.'
    };
  },
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed,
      studentManagementSubjectManagementDisplayed: (state) =>
        state.common.studentManagementSubjectManagementDisplayed
    }),
    groupImportFileInfoName() {
      return this.groupImportFileInfo && this.groupImportFileInfo.name
        ? this.groupImportFileInfo.name
        : '';
    },
    hasSchoolGroups() {
      const { studentManagementSubjectSetupDetected } = this.$store.state.common;
      return studentManagementSubjectSetupDetected;
    }
  },
  methods: {
    resetgroupImportFileInputErrorMessage() {
      this.groupImportFileInputErrorMessage = '';
    },
    resetThenCloseGroupImportModal() {
      this.resetgroupImportFileInputErrorMessage();
      this.$refs['group-import-modal--form'].reset();
      this.groupImportFileInfo = '';

      const groupImportFileInputElement = document.getElementById(this.groupsImportFileInputId);
      groupImportFileInputElement.value = null;

      this.groupImportModalDisplayed = false;
    },
    showDialog(message, type = 'status') {
      this.groupImportGenericDialogDisplayed = true;
      this.groupImportGenericDialogHeader = type === 'status' ? 'Groups Status' : 'Groups Errors';
      this.groupImportGenericDialogMessage = message;
    },
    async saveClientUserImportModal() {
      this.resetgroupImportFileInputErrorMessage();

      if (this.groupImportFileInfo) {
        const formData = new FormData();
        formData.append('file', this.groupImportFileInfo);

        const clientSid = this.$store.state.common.userClientSid;
        const argHash = {
          sid: clientSid === '' ? 'new-client-record' : clientSid,
          payload: formData,
          store: this.$store
        };

        this.groupImportModalSaveActionInProgress = true;

        const cbResponse = await this.$app.stbApiAdapter.postClientSubjectImports(argHash);

        this.groupImportModalSaveActionInProgress = false;

        this.resetThenCloseGroupImportModal();

        if (!cbResponse.error) {
          this.$store.commit(types.COMMON_SET_STUDENT_MANAGEMENT_SUBJECT_SETUP_DETECTED, true);
          this.showDialog(this.groupImportGenericDialogSuccessMessage);
        } else {
          let dialogMessage = 'Group cannot be imported';

          if (cbResponse.refinedMessage) {
            dialogMessage = `${dialogMessage}: ${cbResponse.refinedMessage}.`;
          }
          this.showDialog(
            populateErrorMessage({
              message: dialogMessage,
              store: this.$store
            })
          );
        }
      } else {
        this.groupImportFileInputErrorMessage = 'Group CSV is required';
      }
    },
    handleGroupImportFileInputChange(event) {
      this.resetgroupImportFileInputErrorMessage();

      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop();
        const fileType = file.type;

        if (
          this.groupImportFileExtensionAttr.indexOf(fileExtension) < 0 ||
          !this.groupImportMimeTypeAllowed.includes(fileType)
        ) {
          this.showDialog(
            `Invalid file type: <span class="debug-error-message">${fileName}</span> <br>
            Please select a <span class="debug-error-message">csv</span> file.`,
            'error'
          );
          document.getElementById(this.groupsImportFileInputId).value = '';
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        this.groupImportFileInfo = event.target.files[0];
      }
    },
    handleGroupImportFileSelectionClick() {
      document.getElementById(this.groupsImportFileInputId).click();
    },
    async handleDownloadGroupButtonClick() {
      const cbResponse = await this.$app.stbApiAdapter.getClientSubjectExports({
        store: this.$store
      });

      if (!cbResponse.error) {
        const base64Data = cbResponse.data.computed.source;
        const { filename } = cbResponse.data.computed;
        // get blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'text/csv' });

        const { deviceDetector } = this.$app;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else if (deviceDetector && deviceDetector.checkIfDeviceInIosFamily()) {
          const blobUrl = URL.createObjectURL(blob);
          window.location = blobUrl;
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        let dialogMessage = 'Can not download groups.';

        if (cbResponse.refinedMessage) {
          dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
        }

        this.groupImportGenericDialogMessage = populateErrorMessage({
          message: dialogMessage,
          store: this.$store
        });
        this.groupImportGenericDialogDisplayed = true;
      }
    },
    handlegroupImportGenericDialogOkButtonClick() {
      if (this.groupImportGenericDialogMessage === this.groupImportGenericDialogSuccessMessage) {
        this.$router.go();
      }
      this.groupImportGenericDialogDisplayed = false;
    }
  },
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (
      !this.clientSiteSettingsButtonDiplayed ||
      !this.studentManagementSubjectManagementDisplayed
    ) {
      this.$router.push('/excursions');
    }
  }
};
</script>
<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}

.client-admin-groups-btn-container {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
}

.client-admin-groups-message-container {
  padding-top: 1rem;
  margin-left: 60px;
  margin-right: 50px;
  display: flex;
}
.group-import-modal-form {
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 1rem;
}
</style>
